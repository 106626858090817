import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer'
import '../../styles/downloads.less'
import SEO from '../../components/SEO'
import { navigate } from 'gatsby'
import Header from '../../components/header'
const loadText = require('src/utils').loadText
const data = loadText('downloads')
import { DownloadPageForm } from '../../components/form-biz'
import dayjs from 'dayjs'

// export const isValid = () => {
//   const date = parseInt(localStorage.getItem('DOWNLOAD_PAGE_DATE'))
//   if (isNaN(date)) {
//     return false
//   } else {
//     const diff = dayjs().diff(dayjs.unix(date), 'hour')
//     return diff < 24
//   }
// }

export default function Index() {
  const [isShow, setIsShow] = useState(false)
  useEffect(() => {
    navigate('/downloads/contents/')
  }, [])
  return (
    isShow && (
      <div className="downloads-form">
        <SEO {...data.seo} />
        <div className="downloads-form-topBanner">
          <div className="downloads-form-topBanner-bg full-bg">
            <div className="headerContainer">
              <Header logo="/logo-blue.svg" />
            </div>
            <div className="downloads-form-topBanner-content">
              <h1 className="title">{data.form.title}</h1>
              <div className="subtitle">{data.form.subtitle}</div>
            </div>
          </div>
        </div>
        <div className="downloads-form-content">
          <div className="downloads-form-content-bg">
            <div className="downloads-form-content-body">
              <DownloadPageForm
                onSuccess={() => {
                  localStorage.setItem(
                    'DOWNLOAD_PAGE_DATE',
                    `${dayjs().unix()}`
                  )
                  navigate('/downloads/contents')
                }}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  )
}
